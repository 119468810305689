
import './App.css';


import { Button,Container,Row,Col,Form,Alert } from 'react-bootstrap';
import {useState} from 'react';


function Message({Post}) {

    let [text,setText] = useState("");


    const [x, setX] = useState(false);
    const soldCheckbox = ({ target: { checked } }) => {
        console.log(x, checked);
        setX(checked);
      };

    let [postStatus,setPostStatus] = useState("");


    let [sending,setSending] = useState(false);

    async function doPost(e) {
        e.preventDefault();
        setSending(true);

        var r = await Post(text,x); 

        if (r=='ok')   setPostStatus('ok')
         else
        setPostStatus('error');

        if (true) { 
            setText("");
        }

        setSending(false);
    }

    function textChange(e) {
        setText(e.target.value);
        if  (postStatus!="") setPostStatus("");
    }



  return (

    <Form> 
      
      <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
        <Col>
        <Form.Label>Текст сообщения</Form.Label>
        <Form.Control as="textarea" value={text} onChange={textChange} rows={8} />
        <div className={text.length>180 ?"cntred" : "cntnormal"}>Кол-во символов: {text.length}</div>
        </Col>
      </Form.Group>

      <Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox"  label="Срочная новость" checked={x} onChange={soldCheckbox}  />
  </Form.Group>


      <Form.Group as={Row}>
        <Col>
        <br/>
          <Button className="float-right" disabled={text.length==0 || sending} type="submit" onClick={doPost}>{sending ? "Происходит размещение" : "Разместить"}</Button>
        </Col>
      </Form.Group>

      {postStatus === 'ok' && <Alert variant="success">Новость успешно размещена</Alert>}
      {postStatus === 'error' && <Alert variant="danger">Ошибка размещения новости!</Alert>}

    </Form> 

  );
}

export default Message;
