
import './App.css';

import {useState,useEffect} from 'react';


import { Button,Container,Row,Col,Form } from 'react-bootstrap';

import Message from './Message';
import Login from './Login';
import { computeHeadingLevel } from '@testing-library/react';


function App() {

  
  const [autentificated, setAutentificated] = useState(false);

  const [checked, setChecked] = useState(false);

  async function login(key) {
    let f = await fetch("/login", {
            method:"post",
            headers: {'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify({key})
        });
    
    if (f.status===200) {
        let key = await f.text();
        localStorage.setItem('key',key); 
        setAutentificated(true);    
        return true;   
    } 
    else 
     return false;  

  }  


  
  async function check() {
    let key  =   localStorage.getItem('key'); 
    let f = await fetch("/check", {
            method:"post",
            headers: {'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify({key})
        });
    
        setChecked(true);

    if (f.status===200) {
        setAutentificated(true);       
    } 
  }  

  async function Post(text,breakingnews) {

    let key  = localStorage.getItem("key");

    if (!key) {
      setAutentificated(false); 
      return;
    }
  
  
    let  config = {};
    config.method = "post";
    config.headers = {Authorization:"Bearer "+key,
     'Accept': 'application/json','Content-Type': 'application/json'};
    config.body = JSON.stringify({text,breakingnews});
  
      var result = await fetch('/sendpush',config);

     
        
        if (result.status===200)  return "ok"
  
        if (result.status===401 ||  result.status===403) {
          setAutentificated(false); 
          return "error"
        }


   // console.log("Post",text)
  }


  useEffect(()=>{
    check()
  },[]);


  return (

    <Container>
      <br/>
       {checked &&  (autentificated ? <Message Post={Post}/> : <Login Login={login}/>) }
       
    </Container>

  );
}

export default App;
