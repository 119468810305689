import { computeHeadingLevel } from '@testing-library/react';
import { Button,Container,Row,Col,Form,Alert } from 'react-bootstrap';

import {useState} from 'react';

function Login(props) {

  let [password,setPassword] = useState("");
  let [failure,setFailure] = useState(false);

  async function doLogin(e) {
    console.log(e);
   // alert(e.preventDefault)
    e.preventDefault();

    var result = await props.Login(password);

    if (!result)  {
      setFailure(true);
      setPassword('');
    }
  }


  function  textChange(e)
  {
    setPassword(e.target.value);
    if (failure) setFailure(false);
  }

    return (

      <Form>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Код доступа</Form.Label>
          <Form.Control value={password} onChange={textChange} type="password" placeholder="Код" />
        </Form.Group>

        <Form.Group as={Row}>
        <Col>
        <Button className="float-right" variant="primary" type="submit" onClick={doLogin}>
          Ввод
        </Button>
        </Col>
      </Form.Group>

        {failure && <Alert variant="danger">Код доступа не верный!</Alert>}

      </Form>
      )
    }   

export default Login;
